import './styles.css';

import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdEdit, MdLocationOn } from "react-icons/md";
import { FaBed, FaBath, FaCar, FaSwimmingPool, FaTrash } from "react-icons/fa";
import { TbLineHeight } from "react-icons/tb";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import useScrollIntoView from "../../hooks/useScrollIntoView";
import { BadgeGreen, BadgeRed } from '../Badge';
import { SwitchButton } from '../SwitchButton';

const ListingItem = forwardRef(({ 
  id,
  listing, 
  onDelete, 
  onEdit, 
  selected, 
  toggleLike, 
  onLike, 
  isLiked,
  showEnabled = false,
  onChangeEnabled
}, externalRef) => {
  const [ listingItem, setListingItem ] = useState({});
  // Use the custom hook to get a ref
  const listingRef = useScrollIntoView(selected);
  const navigate = useNavigate();

  useEffect(() => {
    setListingItem(listing);
  }, [ listing ]);

  return (
      <div 
        ref={externalRef || listingRef}
        className="w-full px-4 lg:w-1/2 xl:w-1/3"
      >
        <div className="mb-10 overflow-hidden rounded-lg border border-stroke bg-white hover:shadow-card-2 dark:border-dark-3 dark:bg-dark-2">
          <div className="relative">
            { showEnabled? 
              !listingItem.enabled ? 
                <BadgeRed text="Inactive" color="red" className="absolute right-1 top-1" />
                :
                <BadgeGreen text="Active" color="green" className="absolute right-1 top-1" /> 
              : 
              null
            }
            { showEnabled ? 
              <SwitchButton 
                isChecked={listingItem.enabled} 
                handleOnChange={() => onChangeEnabled(listing)} 
                className="absolute left-2 top-1"
              /> 
              : 
              null
            }
            
            <img 
              src={listingItem?.imgFiles && listingItem?.imgFiles[0]?.savedUrl? listingItem?.imgFiles[0]?.savedUrl : "/images/Logo-Listing Los Cabos.png"} 
              alt="product" 
              className="w-full object-cover h-32 cursor-pointer" 
              onClick={() => navigate(`/category/sale/${id}`)}
            />

            {onLike &&
              <span className="absolute right-2 top-1 inline-block rounded bg-secondary px-[10px] py-1 text-2xl font-medium text-white cursor-pointer">
                {isLiked ? <FcLike onClick={() => toggleLike(id)}/> : <FcLikePlaceholder onClick={() => toggleLike(id)} />}
              </span>
            }
            {onDelete &&
              <span className="absolute right-2 bottom-1 inline-block rounded bg-secondary px-[10px] py-1 text-xl font-medium text-white cursor-pointer">
                <FaTrash onClick={() => onDelete(listingItem.id)} />
              </span>
            }
            {onEdit && (
              <span className="absolute right-10 bottom-1 inline-block rounded bg-secondary px-[10px] py-1 text-xl font-medium text-white cursor-pointer">
                <MdEdit onClick={() => onEdit(listingItem.id)} />
              </span>
            )}

          </div>

          <div className="p-4 h-32">
            <h3 className="text-title">
              <a
                href={`/category/sale/${id}`}
                className="mb-4 block text-lg font-semibold text-dark hover:text-primary dark:text-white xl:text-xl"
              >
                {listingItem.title && listingItem.title !== "" ? listingItem.title : <i>No title</i>}
              </a>
            </h3>
            <p className="flex items-center gap-x-2 text-body-color dark:text-dark-6 text-sm font-medium">
              <span><TbLineHeight /></span> 
              <span>{listingItem.totalArea}m <sup>m2</sup> total</span>
            </p>
            <div className="flex items-center flex-wrap gap-x-4 text-body-color dark:text-dark-6 text-sm font-medium">
              <p className="flex items-center gap-x-1"><FaBed /> {listingItem.bedrooms ?? "-"}</p>
              <p className="flex items-center gap-x-1"><FaBath /> {listingItem.bathrooms  ?? "-"}</p>
              <p className="flex items-center gap-x-1"><FaCar /> {listingItem.garage  ?? "-"}</p>
              <p className="flex items-center gap-x-1"><FaSwimmingPool /> {listingItem.swimmingPool ? "pool" : "-"}</p>
            </div>
          </div>
          <div className="px-4 pb-4">
            <p className="my-5 py-5 text-base text-body-color dark:text-dark-6 text-description">
              {listingItem.description}
            </p>
            <p className="flex mb-3 text-sm text-body-color dark:text-dark-6">
              <MdLocationOn className='h-4 text-green-600'/> {listingItem.location?.cologne}, {listingItem.location?.municipality}
            </p>
      
            <p className="text-lg font-medium text-dark dark:text-white">
              <span>
                {listingItem.type !== "rent" && <span>${listingItem.regularPrice} MXN</span>}
                {listingItem.type === "rent" && <span>${listingItem.regularPrice} MXN / Mes</span>}
              </span>
            </p>
          </div>
        </div>
    </div>
    );
  }
);

export default ListingItem;
