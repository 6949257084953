import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFetchCommunities } from '../hooks/useFetchCommunities';
import LoadingSpinner from './LoadingSpinner';

export default function NavLinks({ toggleHamburguer }) {
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const { data: communities, loading } = useFetchCommunities({ filters: { isFeatured: true } });

  const links = [
    {
      name: 'Listings',
      sublinks: [
        {
          name: 'listings',
          sublink: [
            { name: 'Listings', link: '/listings' },
            { name: 'Inicio Sesión Agente', link: '/login-agent'},
          ],
        },
      ],
    },
    {
      name: 'Desarrollos',
      sublinks: [
        {
          name: 'Desarrollos',
          sublink: [
            { name: 'Descubre tu proximo desarrollo', link: '/communities' },
            ...communities.map(({ id, data: { slug, overview }}) => (
              { name: overview?.title, link: `/community/${slug}` }
            ))
          ]
        },
      ],
      loading
    },
    {
      name: 'Nosotros',
      sublinks: [
        {
          name: 'Nosotros',
          sublink: [
            { name: 'Nosotros', link: '/about' },
            { name: 'Ver Listings', link: '/listings' },
            { name: 'Registro Agente', link: '/register-agent' },
          ],
        },
      ],
    },
  ];


  return (
    <>
      {links.map((LinkItem, index) => (
        <div className='' key={index}>
          {/* Desktop */}
          <div className='px-3 text-left md:cursor-pointer group'>
            <h1 className='uppercase py-7 flex items-center justify-between pr-5 md:pr-0'
              onMouseEnter={() => {
                if(heading !== LinkItem.name) {
                    setHeading(LinkItem.name);
                }
              }}
              onMouseLeave={() => {
                if(heading === LinkItem.name) {
                    setHeading('');
                    setSubHeading('');
                }
              }}
              onClick={() => {
                heading !== LinkItem.name ? setHeading(LinkItem.name) : setHeading('');
                setSubHeading('');
              }}
            >
              {LinkItem.name}
              <span className='ml-2'>
                <ion-icon 
                name={`${heading === LinkItem.name ? 'chevron-up' : 'chevron-down'}`}
                className={`${LinkItem.sublinks.length ? 'inline-block' : 'hidden'} text-gray-600 `}
              >
                </ion-icon>
              </span>
              
            </h1>
            <div>
                <div className='absolute top-20 hidden group-hover:md:block hover:md:block z-50'>
                  <div className='py-3'>
                    <div className='absolute h-4 w-4 bg-white left-3 mt-1 rotate-45'></div>
                  </div>
                  <div className='bg-white shadow-lg p-5 '>
                    {LinkItem.loading && <LoadingSpinner /> }
                    {!LinkItem.loading && LinkItem.sublinks.map((sublinksItem, index) => (
                      <div key={index} className='flex flex-col items-start space-y-2'>
                        <li>
                          <Link to={sublinksItem.sublink[0].link} 
                            className='text-base text-gray-600 hover:bg-gray-200'>
                            {sublinksItem.sublink[0].name}
                          </Link>
                        </li>
                        <div className='w-full bg-gray-400 py-[0.5px]'></div>
                        <div className='grid grid-cols-2 gap-2 '>
                          {sublinksItem.sublink.slice(1).map((sublinkItem, index) => (
                            <li key={index} >
                              <Link to={sublinkItem.link} 
                                style={{display: 'inline-block'}}
                                className='text-base text-gray-600 hover:bg-gray-200'>{sublinkItem.name}</Link>
                            </li>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
          </div>
          {/* Mobile */}
          <div className={`
              ${heading === LinkItem.name ? "md:hidden" : "hidden"}`}>
                {/* sublinks */}
                {
                  LinkItem.sublinks.map((slinks, index) => (
                    <div key={index}>
                      <div>
                        <h1
                        onClick={() => 
                        subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading('') }
                        className='py-4 pl-7 font-semibold pr-5 md:pr-0 flex justify-between items-center '>
                          {slinks.Head}
                          <span className='text-xl md:mt-1 md:ml-2 inline'>
                            <ion-icon name={`${subHeading === slinks.Head ? 'chevron-up' : 'chevron-down'}`}>
                            </ion-icon>
                          </span>
                        </h1>
                        <div className={`${
                          subHeading === slinks.Head
                          ? 'md:hidden'
                          : 'hidden'
                        }`}>
                          {slinks.sublink.map((slink, index) => (
                            <li key={index} className='py-3 pl-14'>
                              <Link
                              to={slink.link}
                              className='hover:text-[#18446b]'
                              onClick={() => toggleHamburguer()}>
                                  {slink.name}
                              </Link>
                            </li>
                          ))}

                        </div>
                      </div>

                    </div>
                  ))
                }
              </div>
        </div>
      ))}
    </>
  )
}
