import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavLinks from "./NavLinks";
import { MdAccountCircle } from "react-icons/md";
import useAuthStatus from "../hooks/useAuthStatus";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";

export default function Navbar() {
  const navigate = useNavigate();
  const { loggedIn, isAdmin } = useAuthStatus();
  const [visitorSession, setVisitorSession] = useState(false);
  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 993 });

  useEffect(() => {
    if (sessionStorage.getItem("visitor")) {
      setVisitorSession(true);
    }
  }, []);

  return (
    <nav
      className={`z-50 ${
        isDesktop ? "" : "sticky"
      } top-0 bg-[#18446b] text-black md:text-white border-b border-black shadow-sm`}
    >
      <div className="flex justify-between items-center px-6 md:px-20 mx-auto z-50">
        <div>
          <img
            onClick={() => navigate("/")}
            className="h-24 p-4 cursor-pointer"
            src="/images/Logo-Listing Los Cabos.png"
            alt=""
          />
        </div>

        <div
          onClick={() => setOpen(!open)}
          className={`z-50 text-4xl  md:hidden ${
            open ? "text-black" : "text-white"
          }`}
        >
          <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
        </div>

        <div className="hidden md:block">
          {/* space-x-7 */}
          <ul className="flex space-x-16">
            <NavLinks />

            {/* <li>
                    <p className='py-7 uppercase cursor-pointer'>Press Room</p>
                </li> */}
            {/* <li className=''>
                    <p className='py-7 uppercase cursor-pointer'>Contact</p>
                </li> */}
          </ul>
        </div>

        <div className="md:flex items-center justify-center space-x-5 hidden">
          {loggedIn && (
            <>
              <>
                {isAdmin ? (
                  <>
                    <Link to={"/admin"}>
                      <span className="flex flex-row space-x-2">
                        <span>Mi Cuenta</span>
                        <MdAccountCircle className="text-2xl cursor-pointer" />
                      </span>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={"/profile-agent"}>
                      <span className="flex flex-row space-x-2">
                        <span>MI CUENTA</span>
                        <MdAccountCircle className="text-2xl cursor-pointer" />
                      </span>
                    </Link>
                  </>
                )}
              </>
            </>
          )}

          <Link
            to={visitorSession ? "/profile" : isAdmin ? "/admin" : "/sign-in"}
            className="uppercase text-sm tracking-widest text-white hover:text-[#18446b] 
          border border-white px-5 py-1 hover:bg-white duration-300 "
          >
            {visitorSession ? "Profile" : isAdmin ? "Dashboard" : "Sign In"}
          </Link>

          <div className="flex items-center justify-center pl-4">
            <BsFillTelephoneFill className="text-xl cursor-pointer" />
            <p className="uppercase text-sm tracking-widest px-5 py-1 cursor-pointer">
              55 1107 7339
            </p>
          </div>
        </div>

        {/* mobile */}
        <ul
          className={`fixed -z-10 md:hidden bg-white w-full h-full bottom-0 py-32 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}`}
        >
          {/* <NavLinks toggleHamburguer={() => setOpen(!open)}/> */}

          <li
            onClick={() => {
              setOpen(!open);
              navigate("/listings");
            }}
            className="px-3 mb-7"
          >
            Listings
          </li>

          <li
            onClick={() => {
              setOpen(!open);
              navigate("/communities");
            }}
            className="px-3 mb-7"
          >
            Communities
          </li>

          <li
            onClick={() => {
              setOpen(!open);
              navigate("/login-agent");
            }}
            className="px-3 mb-7"
          >
            Agents
          </li>

          <li
            onClick={() => {
              setOpen(!open);
              navigate("/profile-agent");
            }}
            className="px-3 mb-7"
          >
            {loggedIn && (
              <>
                <>
                  {isAdmin ? (
                    <>
                      <Link to={"/admin"}>
                        <span className="flex flex-row space-x-2">
                          <span>MY ACCOUNT</span>
                          <MdAccountCircle className="text-2xl cursor-pointer" />
                        </span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to={"/profile-agent"}>
                        <span className="flex flex-row space-x-2">
                          <span>MY ACCOUNT</span>
                          <MdAccountCircle className="text-2xl cursor-pointer" />
                        </span>
                      </Link>
                    </>
                  )}
                </>
              </>
            )}
          </li>

          <Link
            onClick={() => {
              setOpen(!open);
              navigate("/sign-in");
            }}
            to={visitorSession ? "/profile" : isAdmin ? "/admin" : "/sign-in"}
            className="px-3 py-1 ml-3 mb-7 uppercase text-sm tracking-widest text-[#18446b]
             hover:text-white border border-[#18446b] hover:bg-[#18446b] duration-300"
          >
            {visitorSession ? "Profile" : isAdmin ? "Dashboard" : "Sign in"}
          </Link>
        </ul>
      </div>
    </nav>
  );
}
