import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import CommunityItem from '../components/CommunityItem';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    EffectFade,
    Autoplay,
    Pagination,
    Navigation,
} from 'swiper';
import 'swiper/css/bundle';
import { FACILITY_TYPES, OPERATION_TYPES } from '../shared/constants';
import ButtonGroup from '../components/ButtonGroup';
import './styles.css';

import image4 from '../assets/4.png';
import image5 from '../assets/5.jpg';
import image6 from '../assets/6.png';
import { useFetchCommunities } from '../hooks/useFetchCommunities';
import ProgressBar from '../components/ProgressBar';

export default function Home() {
    SwiperCore.use([ Pagination, Navigation, EffectFade, Autoplay]);
    const sliderImages = [ image4, image5, image6 ];

    // autocomplete
    const navigate = useNavigate();
    const [ operationType, setOperationtype ] = useState('');
    const [ facilityType, setFacilityType ] = useState('apartment');
    const [ searchBy, setSearchBy ] = useState('');

    const { data: communities, loading: loadingCommunities } = useFetchCommunities({ filters: { isFeatured: true } });

    const onSearch = async () => {
        const filterParam = new URLSearchParams();

        console.log(operationType, facilityType, searchBy);

        operationType && filterParam.append('operation', operationType);
        facilityType && filterParam.append('facility', facilityType);
        searchBy && filterParam.append('search', searchBy);

        // Convert the filterParam to a string and navigate to the search page with query parameters
        navigate(`listings?${filterParam.toString()}`);
    }


  return (
    <main className="flex-1 min-w-0">
      <div className='font-montserrat'>
        <Swiper
          style={{ zIndex: -100}}
          slidesPerView={1}
          effect='fade'
          modules={[EffectFade]}
          autoplay={{delay: 3000}}>
            {sliderImages.map((url, index) => (
              <SwiperSlide key={index}>
                <div className='relative w-screen overflow-hidden h-[80vh] flex flex-col items-center md:items-start justify-center px-6 md:px-20'
                  style={{background: `url(${sliderImages[index]}) center no-repeat`,
                  backgroundSize: "cover"}}>  
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className={`absolute z-10 top-[25vh] px-6 md:z-50 md:top-72 md:pl-20 md:px-0 '}`}>
          <h1 className='font-palatino-lt-serif text-l md:text-3xl text-white uppercase font-medium'>Tu nuevo hogar te espera.</h1>
          <h2 className='text-4xl text-white uppercase font-palatino-lt-serif font-semibold mb-4 md:mb-2 md:text-7xl'>Encuentra tu casa</h2>

          <div className='flex items-center justify-center space-x-4 bg-white w-fit h-14 p-2 mb-2'>
            <ButtonGroup buttons={OPERATION_TYPES} onClickButton={(selection) => setOperationtype(selection.key)} /> 
          </div>
          <div className='items-center justify-center md:space-x-4 bg-white w-full h-fit p-2 md:flex md:w-auto'>
            <div className='flex'>
              <select 
                id="facility-types"
                onChange={(event) => setFacilityType(event.target.value)}
                className='py-5 hover:bg-[#18446b] focus:outline-none hover:text-white duration-300 w-full mb-2 md:mb-0 md:w-auto'>
                {FACILITY_TYPES.map((type, index) =><option key={index} value={type.key}>{type.value}</option>)}
              </select>

              <div className='hidden items-center justify-start md:flex'> 
                <p className='text-3xl font-light text-gray-300 pl-3'>|</p>
              </div>
            </div>

            <div className='flex'>
              <div className='w-full mb-2 md:mb-0 md:w-auto'>
                  <input 
                    type="text"
                    placeholder={"Ciudad, Dirección, C.P."}
                    value={searchBy}
                    onChange={(event) => setSearchBy(event.target.value)}
                    className={'p-5 w-full md:w-auto'}  />
              </div>
            </div>

            <div className='hidden items-center justify-end md:flex'> 
              <p className='text-3xl font-light text-gray-300'>|</p>
            </div>

            <div className='relative'>
              <button 
                onClick={onSearch}
                className='flex items-center justify-center bg-[#18446b] hover:bg-opacity-70 text-white p-5 font-medium text-base uppercase tracking-widest duration-300 w-full md:w-auto'>
                  <AiOutlineSearch className='text-white text-lg mr-2 duration-300'/> Buscar
              </button>
            </div>
          </div>
        </div>
        

        {/*<div className={`${isDesktop ? 'flex items-center justify-between px-20' : 'flex flex-col items-center justify-center px-6'}`}>*/}
        <div className='flex flex-col items-center justify-between px-10 md:px-20 xl:flex-row mb-14 xl:mb-0'>
          <div className='bg-white py-12 xl:py-16 flex flex-col items-start justify-center'> 
            <h1 className='text-3xl font-medium font-heibird'>What's your home worth?</h1>
            <p>Get a <span className='font-xl font-bold uppercase'>free </span>Comparative Market Analysis in today's market.</p>
          </div>

          {/*<div className={`${isDesktop ? 'flex border border-gray-200' : 'flex flex-col items-center justify-center space-y-4 w-full pb-6'}`}>*/}
          <div className='flex flex-col md:flex-row border border-gray-200'>
            <input 
              type="text"
              placeholder='Your home address'
              className='w-full md:w-fit py-3 pl-4 border border-gray-300 focus:outline-none focus:border-cyan-600 transition-300' />

            <div className='hidden md:flex items-center justify-center'> 
              <p className='text-3xl font-light text-gray-300 p-2'>|</p>
            </div>

            <input 
              type="text"
              placeholder='Your email address'
              className='w-full md:w-fit py-3 pl-4 border border-gray-300 focus:outline-none focus:border-[#18446b] transition-300' />
            <button className='w-full md:w-32 bg-[#18446b] hover:opacity-70 text-white text-lg tracking-widest font-medium py-3 transition-300'>Get value</button>
          </div>

        </div>

        <div className=' bg-[#18446b] px-6 py-12'>

          <div className='flex-column md:flex items-center justify-between py-4 md:py-10 px-10'>
            <h1 className='font-montserrat text-white font-small text-4xl md:text-5xl' >Nuestros <br /> Desarrolladores</h1>
          </div>

          { loadingCommunities && <div className='mt-2'><ProgressBar /></div> }
          <Swiper
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={20}
            navigation
            modules={[EffectFade, Navigation]}>
              {communities.map(({ id, data: { slug, imgCoverUrl: img, overview, isFeatured }}) => (
                <SwiperSlide key={id}>
                  <CommunityItem
                    link={`/community/${slug}`}
                    img={img}
                    caption={overview?.title}
                    isFeatured={isFeatured} />
                </SwiperSlide>
              ))}
            </Swiper>
        </div>

        <div className='bg-white h-[50px]'></div>
        
      </div>
    </main>
  )
}
