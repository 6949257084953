import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IoIosOptions } from 'react-icons/io';
import { CiLocationOn } from 'react-icons/ci';

import { Map, buildMarkerFromListing } from '../../components/PropertiesMap';

import useScrollIntoView from '../../hooks/useScrollIntoView';
import useModal from '../../hooks/useModal';
import { useFetchListing } from '../../hooks/useFetchListing';

import FilterModal from './FilterModal';
import ListingCards from '../../components/ListingCards';
import useWindowSizeTracker from '../../hooks/useWindowWizeTracker';

export default function Listings() {
  const filterModal = useModal();
  const { windowSize } = useWindowSizeTracker();

  //const [ loading, setLoading ] = useState(true)
  const [ selectedListingId, setSelectedListingId ] = useState(null);
  const [ mapVisible, setMapVisible ] = useState(!(windowSize.width < 993));
  const [ mapFullWidth, setMapFullWidth ] = useState(!(windowSize.width < 850));

  const { search: query } = useLocation();
  const queryParams = new URLSearchParams(query);

  const operation = queryParams.get('operation');
  const facility = queryParams.get('facility');
  const search = queryParams.get('search');

  const [ filters, setFilters ] = useState({
    'type': operation,
    'property': facility,
    'searchBy': search,
    'bedrooms': undefined,
    'bathrooms': undefined,
    'garage': undefined,
    'antiquity': undefined,
    'minPrice': undefined,
    'maxPrice': undefined,
  });
  const [ markers, setMarkers ] = useState([]);
  const { data, loading, fetchFilteredData } = useFetchListing({ filters });

  // Fetch all listings
  useEffect(() => {
    fetchFilteredData();
  }, []);

  useEffect(() => {
    const newMarkers = data.map(({ id, data}) => buildMarkerFromListing({ id, data }));
    setMarkers(newMarkers);
  }, [data]);

  useEffect(() => {
    setMapVisible(!(windowSize.width < 993));
    setMapFullWidth(windowSize.width < 850);
  },[ windowSize ]);

  const handleMarkerClick = (listingId) => {
    setSelectedListingId(listingId);
  }

  // Use the custom hook to get a ref
  const listingRef = useScrollIntoView(selectedListingId);

  const handleToggleMap = () => {
    setMapVisible(!mapVisible); // Toggle map visibility when the button is clicked
  }

  const onChangeFilter = (filters) => {
    filterModal.closeModal();
    setFilters(filters);
  }

  return (
    <div className={`flex items-start justify-between overflow-hidden w-full`}>
      <div className={`overflow-y-auto w-full max-h-[85vh]`}>
        <div className='flex flex-col xl:flex-row items-center justify-center mt-2 xl:space-x-2 mb-2 md:mb-0'>
            <div className='relative'>
              <button className='flex items-center justify-center bg-[#18446b] hover:opacity-70 text-white text-base p-2 xl:p-5 w-[25vh] font-medium duration-300'
              onClick={filterModal.openModal}>
                  Filtros
                  <IoIosOptions className='text-white text-lg ml-2 duration-300'/>
              </button>
            </div>

            <div className='relative'>
              <button className='flex items-center justify-center bg-[#18446b] hover:opacity-70 text-white text-base p-2 xl:p-5 w-[25vh] font-medium duration-300 whitespace-nowrap xl:mt-0 mt-2'
              onClick={handleToggleMap}>
                  {mapVisible ? 'Ocultar Mapa' : 'Mostrar Mapa'}
                  <CiLocationOn className='text-white  text-lg ml-2 
                  duration-300'/>
              </button>
            </div>
        </div>
        <FilterModal open={filterModal.isOpen} values={filters} onChangeDropdown={onChangeFilter} />
        <ListingCards 
          loading={loading} 
          data={data} 
          selectedListingId={selectedListingId} 
          listingRef={listingRef} 
        />
      </div>

      {mapVisible && (
        <div className='w-full h-fit overflow-hidden'>
          <Map markers={markers} onMarkerClick={handleMarkerClick}/>
        </div>
      )}

   </div>
  )
}
