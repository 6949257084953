import React from "react";
import image5 from "../assets/5.jpg";

export default function About() {
  return (
    <div
      className="w-full  min-h-[60vh] "
      style={{
        background: `url(${image5}) no-repeat center`,
        backgroundSize: "cover",
      }}
    >
      <div
        className="w-full  min-h-[60vh] px-10"
        style={{ backgroundColor: "rgba(0, 15, 45, 0.3)" }}
      >
        <div className="w-full pt-16 pb-16">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-20 max-w-[1200px] mx-auto">
            <h1 className="text-6xl text-center text-white font-semibold">
              Nosotros
            </h1>
            <div></div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-20 max-w-[1200px] mx-auto">
          <div>
            <h2 className="text-2xl text-white text-center font-semibold pb-2">
              Listing Los Cabos By Encuentra Tu Casa
            </h2>
            <p className="text-lg text-white text-justify leading-8">
              Encuentra tu Casa , ha desarrollando un nuevo sitio web dedicado a la compra, 
              venta y renta de propiedades así como servicios y empleos en Los Cabos. 
              Nuestro objetivo es ofrecer a nuestros clientes una plataforma intuitiva y eficiente,
              donde puedan encontrar su hogar ideal o una inversión inmobiliaria con facilidad.
              Nuestra amplia trayectoria y profundo conocimiento del mercado de la CDMX y otros estados de la república
              nos permiten brindar un servicio de alta calidad y asesoramiento 
              personalizado ahora en los Cabos BCS.
            </p>
          </div>
          <div>
            <h2 className="text-2xl text-white text-center font-semibold pb-2">
              ¿Qué es Listing Los Cabos?
            </h2>
            <p className="text-lg text-white text-justify leading-8">
              Nos enorgullece ser una empresa confiable y reconocida por nuestra profesionalidad 
              y compromiso con la satisfacción del cliente por más de 20 años. ¡te invitamos a Explorar las mejores oportunidades 
              inmobiliarias en Los Cabos con nosotros a traves de nuestro sitio web!
            </p>
          </div>
        </div>
        <div>
          <p className="pt-10 text-lg text-white text-center">
            Información y ventas comunicate con nosotros <strong> 5511077339,5555239492</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
