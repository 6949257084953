import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

import { IconFeature } from "../components/Listing/IconFeature";
import { ListingSection } from "../components/Listing/ListingSection";
import { FeaturesAndAmenitiesSection } from "../components/Listing/FeaturesAndAmenitiesSection";
import { Map, buildMarkerFromListing } from "../components/PropertiesMap";
import { PresentedBySection } from "../components/Listing/PresentedBySection";
import { GetInTouchForm } from "../components/GetInTouchForm";
import { useFetchListingById } from "../hooks/useFetchListingById";
import { PngIcon } from "../components/PngIcon";
import Spinner from "../components/Spinner";
import Modal from "../components/Modal";
import { ListingCarousel } from "../components/ListingCarousel";

import SignIn from "./SignIn";

import { sendEmailWithFirebase } from "../services/firebase-email";
import useModal from "../hooks/useModal";

import BedImage from "../assets/icons/bed.png";
import BathImage from "../assets/icons/bath.png";
import GarageImage from "../assets/icons/garage.png";
import YearImage from "../assets/icons/year.png";
import SqftBuiltImage from "../assets/icons/sqft_built.png";
import SqftFullImage from "../assets/icons/sqft_full.png";
import { NotFound } from "../components/NotFound";

export default function Listing() {
  const tabs = [ 'Descripción General' ];

  const params = useParams();
  const { data: dataListing, loading: loadingListing } = useFetchListingById({ id: params.listingId });
  const [ listing, setListing ] = useState({});
  const [ user, setUser ] = useState({});
  const [ authenticatedUser, setAuthenticatedUser ] = useState({});
  const [ listingNotEnabled, setListingNotEnabled ] = useState(false);

  const [activeTab, setActiveTab] = useState("Descripción General");

  const visitorModal = useModal();
  const auth = getAuth();
  const isDesktop = useMediaQuery({ minWidth: 993 });

  useEffect(() => {
    if(dataListing && dataListing?.id) {
      // only show it if it's enabled or belong to the logged in user
      if (dataListing?.data?.enabled || dataListing?.data?.userRef === authenticatedUser.uid) {
        setListing(dataListing.data);
        setUser(dataListing.user);
        console.log("user -> ", dataListing.user);
      } else{
        toast.error("You don't have permissions to visualize this Listing");
        setListingNotEnabled(true);
      }
    }
  }, [dataListing, authenticatedUser]);


  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setAuthenticatedUser(user);
        return;
      } else {
        /*const retrievedEmail = sessionStorage.getItem("visitor");
        if (retrievedEmail === null) {
          visitorModal.openModal();
        }*/
      }
    });
  });

  if (loadingListing) {
    return <Spinner />;
  }

  if (listingNotEnabled) {
    return <NotFound />;
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabs = () => {
    return tabs.map((tab) => (
      <li
        key={tab}
        className={`cursor-pointer ${
          activeTab === tab
            ? "border-b border-gray-500"
            : "border-b-transparent"
        }`}
        onClick={() => handleTabClick(tab)}
      >
        {tab}
      </li>
    ));
  };

  const sendContactEmail = async ({ 
    firstName,
    lastName,
    email,
    phone,
    comment
  }) => {
    await sendEmailWithFirebase({ 
      to: [user.email], 
      subject: 'Una nueva persona esta interesada en tu propiedad', 
      content: `
        </br>
        <img src="https://listingloscabos-client.vercel.app/images/logos/Logo-Listing Los Cabos-Fondo azul.png" width="150px" alt="Logo" title="Logo" style="border: 0;">
        </br>
        </br>

        Una nueva persona esta interesada en tu propiedad: ${listing?.title} - ${listing?.location?.fullAddress}
        </br>
        </br>

        Información de contacto
        <ul>
          <li>Nombre: ${firstName}</li>
          <li>Apellido: ${lastName}</li>
          <li>Email: ${email}</li>
          <li>Teléfono: ${phone}</li>
          <li>Commentario: ${comment}</li>
        </ul>

        Saludos,
      `
    });
    toast.success("Nos pondremos en contacto contigo pronto.!");
  }

  return (
    <main className="flex-1 min-w-0">
      <div className="h-auto w-full font-palatino-lt-serif">
        {/* Carousel component */}
        <ListingCarousel imgFiles={listing?.imgFiles} videoFiles={listing?.videoFiles} />

        {/* Sub-header component */}
        <div className="bg-[#18446b] w-full h-fit flex flex-col items-start justify-center space-y-0 px-10 md:px-36 py-8">
          <div className="flex items-center justify-between w-full flex-col xl:flex-row">
            <div className="w-full sm:w-auto">
              <p className="text-white font-normal text-2xl md:text-3xl tracking-wider">
                Precio
              </p>
              <p className="text-white font-normal text-4xl md:text-7xl tracking-wider pb-10 xs:pb-0">
               ${listing?.regularPrice ?? 0} MXN
              </p>
            </div>
            <div className="flex items-start justify-center flex-col sm:space-x-12 md:flex-row">
              <IconFeature text={`${listing.bedrooms} Recámaras`} icon={<PngIcon src={BedImage} />} />
              <IconFeature text={`${listing.bathrooms} Baños`} icon={<PngIcon src={BathImage} />} />
              <IconFeature text={`${listing.garage ? listing.garage: '0' } Estacionamiento`} icon={<PngIcon src={GarageImage} />} />
              <IconFeature text={`${listing.antiquity} Edad Inmueble`} icon={<PngIcon src={YearImage} />} />
              <IconFeature text={`${listing.usefulArea} </br> M² Const. `} icon={<PngIcon src={SqftBuiltImage} />} />
              <IconFeature text={`${listing.totalArea} </br> M² Terreno.`} icon={<PngIcon src={SqftFullImage} />} />
            </div>
          </div>
        </div>

        {/* main body */}
        <div className="w-full h-full space-y-0 px-10 xl:px-36 py-8">
          <ul className="flex justify-between space-x-4 md:space-x-16 mr-12">
            {renderTabs()}
          </ul>

          <ListingSection cols={1} childrenClassName={"md:grid-cols-2"}>
            <div className="flex flex-col gap-y-3">
              <h1 className="text-3xl">{listing.title}</h1>
              <p className="text-justify text-xl" dangerouslySetInnerHTML={{__html: listing.description }} />
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-3xl text-center">Contacta con el anunciante</h2>
              <GetInTouchForm onSubmit={(data) => sendContactEmail(data)}/>
            </div>
          </ListingSection>

          <ListingSection title="Caracteristicas y Amenidades" >
            <FeaturesAndAmenitiesSection data={listing} />
          </ListingSection>

          <ListingSection title="Ubicación" >
            <div className="flex flex-col justify-center">
              <h2 className="text-3xl text-center pb-2">{ listing?.location?.fullAddress }</h2>
              <Map markers={[buildMarkerFromListing({ data: listing })]} 
                centerMap={ listing?.location?.geolocation } />
            </div>
          </ListingSection>

          <ListingSection title="Publicado por " >
            <PresentedBySection profile={user} listing={listing} />
          </ListingSection>
        </div>

        <Modal isOpen={visitorModal.isOpen}>
          <SignIn />
        </Modal>
      </div>
    </main>
  );
}
