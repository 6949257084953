import { InputText } from "./InputText";
import { Textarea } from "./Textarea";

export const DescriptionListingComponent = ({
  onChange,
  title,
  description,
}) => {
  return (
    <>
      <h1 className="text-2xl text-center mt-6 mb-6 font-semibold">
        Descripción
      </h1>

      <InputText title="Titulo" value={title} onChange={onChange} id="title" />

      <Textarea
        onChange={onChange}
        value={description}
        title="Descripción"
        id="description"
      />
    </>
  );
};
