import { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { InputText } from "./InputText";
import { InputNumber } from "./InputNumber";

const locationMapData = [
  {
    key: 'country',
    label: 'country',
    googleMapKey: 'country'
  },
  {
    key: 'state',
    label: 'state',
    googleMapKey: 'administrative_area_level_1'
  },
  {
    key: 'municipality',
    label: 'municipality',
    googleMapKey: 'locality'
  },
  {
    key: 'cologne',
    label: 'cologne',
    googleMapKey: 'sublocality_level_1'
  },
  {
    key: 'street',
    label: 'street',
    googleMapKey: 'route'
  },
  {
    key: 'outdoorNumber',
    label: 'outdoor number',
    googleMapKey: 'street_number'
  },
  {
    key: 'cp',
    label: 'Zip Code',
    googleMapKey: 'postal_code'
  }
];

const initState = {
  fullAddress: "",
  outdoorNumber: 0,
  interiorNumber: 0,
  country: "",
  cp: 0,
  state: "",
  municipality: "",
  cologne: "",
  street: "",
  geolocation: {
    lat: "",
    lng: ""
  }
};

export const LocationListingComponent = ({
  onChange,
  listingLocation
}) => {

  const [location, setLocation] = useState(initState);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    language: 'en',
    options: {
      types: ['address'],
      componentRestrictions: { country: 'mx' }
    },
    onPlaceSelected: (place) => mapTolocation(place)
  });

  useState(() => {
    listingLocation && setLocation(listingLocation);
  }, [JSON.stringify(listingLocation)]);
  
  const mapTolocation = (place) => {
    const newLocation = { 
      ...location,
      geolocation: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
      fullAddress: place.formatted_address
    };

    locationMapData.forEach(locationMap => {
      const key = locationMap['key'];
      const googleMapKey = locationMap['googleMapKey'];
      const addressComponent = place.address_components.find(component => component.types.includes(googleMapKey));
      newLocation[key] = addressComponent?.long_name.toLowerCase() ?? initState[key];
    });
    newLocation['geolocation'] = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    }

    setLocation(newLocation);
    onChange(newLocation);
  }

  const handleFullAddressChange = (e) => {
    console.log("handleFullAddressChange -> ", e.taget )
    setLocation({...initState, fullAddress: e.target?.value ?? "" });
  };

  return (
    <>
      <h1 className="text-2xl text-center mt-6 mb-6 font-semibold">Ubicación</h1>

      <InputText 
        title="Dirección Completa" 
        id="fullAddress" 
        value={location.fullAddress} 
        inputRef={el => ref.current = el} 
        onChange={handleFullAddressChange} 
      />

      <InputText title="Ciudad" id="country" value={location.country} inputClassName="capitalize" disabled />

      <InputText title="Estado" id="state" value={location.state} inputClassName="capitalize" disabled />

      <InputText title="Municipio" id="municipality" value={location.municipality} inputClassName="capitalize" disabled />

      <InputText title="Colonia" id="cologne" value={location.cologne} inputClassName="capitalize" disabled />

      <InputText title="Calle" id="street" value={location.street} inputClassName="capitalize" disabled />

      <div className="flex gap-3">
        <InputText title="Número Exterior" id="outdoorNumber" value={location.outdoorNumber} disabled />

        <InputNumber
          id="interiorNumber"
          title="Número Interior"
          value={location.interiorNumber} 
          onChange={(e) => setLocation({...location, interiorNumber: e.taget?.value })}
        />
      </div>

      <InputText title="Codigo Postal" id="cp" value={location.cp} disabled />
      
    </>
  );
};
