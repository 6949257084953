import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";

export default function SignIn() {
  const navigate = useNavigate();
  const [allEmails, setAllEmails] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  useEffect(() => {
    async function fetchEmails() {
      try {
        const visitorRef = collection(db, "visitors");
        const q = query(visitorRef);
        const querySnap = await getDocs(q);

        // Extract and store all email addresses in an array
        const emails = [];

        querySnap.forEach((doc) => {
          const data = doc.data();
          if (data.email) {
            emails.push(data.email);
          }
        });
        setAllEmails(emails);
      } catch (error) {
        console.error("Error fetching emails", error);
      }
    }

    fetchEmails();
  }, []);

  function handleChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (allEmails.includes(email)) {
      sessionStorage.setItem("visitor", JSON.stringify(email));
      const retrievedEmail = sessionStorage.getItem("visitor");
      console.log(retrievedEmail);
      navigate("/listings");
    } else {
      toast.error("Por favor, inténtelo de nuevo o cree una cuenta.");
    }
  }

  return (
    <div className="max-w-2xl mx-auto h-[88vh] px-6 md:px-0 font-montserrat flex items-center justify-center ">
      <div className="w-full">
        <h1 className="font-palatino-lt-serif text-3xl md:text-5xl font-medium uppercase">
          Inicio Sesión Usuario
        </h1>

        <form onSubmit={handleSubmit}>
          <div className="mt-8 mb-3 md:mb-8">
            <label className="text-sm mb-1" htmlFor="">
              Correo Electrónico - <span className="text-gray-500">requerido</span>
            </label>
            <input
              onChange={handleChange}
              id="email"
              value={email}
              className="w-full py-2 border border-gray-300 focus:outline-none focus:border-cyan-600
                  pl-4 caret-gray-300"
              type="text"
            />
          </div>

          <div className="flex flex-col md:flex-row md:space-x-4 space-y-3 md:space-y-0 mt-6 mb-3 md:mb-8">
            <button
              type="submit"
              className="uppercase w-full py-3 text-white text-sm font-medium tracking-wider
              bg-[#18446b] hover:bg-opacity-70 duration-300"
            >
              Iniciar Sesión
            </button>

            <div className="relative w-full">
              <Link
                to={"/sign-up"}
                className="flex items-center justify-center uppercase w-full py-3 text-black text-sm font-medium tracking-wider
                  border border-transparent hover:border-[#18446b] duration-300"
              >
                Crear Cuenta
                <BsArrowRight className="ml-2" />
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
