import { PngIcon } from "../PngIcon";
import WhatsappSvg from "../../assets/icons/whatsapp.svg";

const _ = require("lodash");

const agentFields = [
  { label: 'Empresa', keyField: 'company' },
  { label: 'Teléfono', keyField: 'phone' },
  { label: 'Email', keyField: 'email' },
  { label: 'Sitio Web', keyField: 'website' }
];
export const PresentedBySection = ({ profile, listing = {} }) => {
  return <div className="flex flex-wrap w-full">
    <div className="w-full lg:w-6/12 xl:w-2/12 flex justify-center h-full md:pr-4">
      <img className="object-fill" src={profile?.profileImage} alt="featured" />
    </div>
    <div className="w-full lg:w-6/12 xl:w-10/12 ">
      <div className="flex flex-col justify-start gap-5 h-full">
        <h3 className="text-4xl capitalize">{profile?.firstName} {profile?.lastName}</h3>
        <div className="w-full flex flex-col xl:flex-row text-xl">
          {agentFields.map((field, index) => 
            <div key={index} className="basis-1/4 flex flex-col mb-4 md:mb-0">
              <div>{ field.label }</div>
              <div>{ _.get(profile, field.keyField, "-") }</div>
            </div>
          )}
        </div>
        <div className="flex space-x-4">
          <a 
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?phone=${profile?.whatsappNumber}&text=${encodeURI(`Hello! I'm interested in your listing "${listing?.title}": ${window.location.href}`)}`}
            className="flex flex-row items-center bg-white border border-green-700 text-green-700 hover:bg-green-500 duration-300 whitespace-nowrap px-9 uppercase text-sm font-medium"
          >
            <PngIcon src={WhatsappSvg} className="h-[4vh]" /> <span className="pl-2">Whatsapp</span>
          </a>
        </div>
      </div>
    </div>
  </div>
};
