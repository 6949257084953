import { OPERATION_TYPES } from "../shared/constants";

export const TypeOperation = ({ type, onClick }) => {
  return (
    <>
      <p className="text-md mt-6">Tipo de Operación</p>

      <div className="flex space-x-3">
        {OPERATION_TYPES.map((operationType, index) => (
          <button
            key={index}
            type="button"
            id={`type`}
            value={operationType.key}
            onClick={onClick}
            className={`px-7 py-3 w-full font-medium text-xs uppercase 
              shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg 
              transition duration-150 ease-in-out ${
                type !== operationType.key
                  ? "bg-white text-black"
                  : "bg-[#18446b] text-white"
              }`}
          >
            {operationType.value}
          </button>
        ))}
      </div>
    </>
  );
};
