import basic from "../assets/real_estate_plan.jpg";

export const OPERATION_TYPES = [
  { key: 'sale', value: 'Venta' },
  { key: 'rent', value: 'Renta' },
  { key: 'developments', value: 'Desarrollos' }
];

export const FACILITY_TYPES = [
  { key: 'apartment', value: 'Departamento' },
  { key: 'house', value: 'Casa' },
  { key: 'condominium', value: 'Condominio' },
  { key: 'commercial_space', value: 'Espacio Comercial' },
  { key: 'commercial_lot', value: 'Terreno' }
];

export const COMMUNITIES = [
  {
    href:'/community/cabo-del-sol',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Cabo%20Del%20Sol.jpeg',
    label: 'Cabo del Sol'
  },
  {
    href:'/community/diamante',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/unnamed-2.91.jpg',
    label: 'Diamante'
  },
  {
    href:'/community/cabo-bello',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Cabo%20Bello%20Header%20v2.30.jpg',
    label: 'Cabo Bello'
  },
  {
    href:'/community/querencia',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Querencia.jpeg',
    label: 'Querencia'
  },
  {
    href:'/community/quivira-hub',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Quivira%20View.jpg',
    label: 'Quivira Hub'
  },
  {
    href:'/community/palmilla',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Palmilla.jpeg',
    label: 'Palmilla'
  }
];

export const CONTACTS = [
  { label: "Nuestra Oficina", href: "/our-office" },
  { label: "Contacto", href: "/contacto" },
  { label: "Agentes", href: "/our-agents" },
];

export const ICONS = [
  {
    name: "logo-facebook",
    link: "/",
    src: "/images/iconos/Facebook-Contorno dorado transparente.png",
    hover: "/images/iconos/Facebook-Dorado-Blanco.png",
  },
  {
    name: "logo-instagram",
    link: "/",
    src: "/images/iconos/Instagram-Contorno dorado transparente.png",
    hover: "/images/iconos/Instagram-Dorado-Blanco.png",
  },
  {
    name: "logo-tiktok",
    link: "/",
    src: "/images/iconos/TikTok-Contorno dorado transparente.png",
    hover: "/images/iconos/TikTok-Dorado-Blanco.png",
  },
  {
    name: "logo-twitter",
    link: "/",
    src: "/images/iconos/Twitter-Contorno dorado transparente.png",
    hover: "/images/iconos/Twitter-Dorado-Blanco.png",
  },
];

export const LISTINGS = [
  { label: "Listings", href: "/listings" },
  { label: "Developer Zone", href: "/developer-zone" },
  { label: "L.C. Listings Opportunities", href: "/listings-opportunities" },
];

export const CITIES = [
  { label: "Los Cabos", href: "/" },
  { label: "Baja California", href: "/baja-california" },
  { label: "Acapulco", href: "/acapulco" },
];

export const FEATURES_AND_AMENITIES = [
  { label: "Alberca", firebaseKey: "swimmingPool" },
  { label: "Jardin", firebaseKey: "garden" },
  { label: "Áreas Verdes", firebaseKey: "greenAreas" },
  { label: "Vigilancia", firebaseKey: "surveillance" },
  { label: "Acceso a Internet", firebaseKey: "internetAccess" },
  { label: "Salón Usos Multiples", firebaseKey: "multipurposeRoom" },
  { label: "Terrazas", firebaseKey: "terraces" },
  { label: "Cuarto de Servicio", firebaseKey: "serviceRoom" },
  { label: "Gym", firebaseKey: "gym" },
  { label: "Lobby", firebaseKey: "lobby" },
];

export const SUBSCRIPTION_PLANS = [
  {
    id: 1,
    src: basic,
    title: "Basico",
    description: "Ideal para agentes independientes o pequeñas agencias inmobiliarias, Publicación máxima 10 propiedades",
    price: "2,000,00 MXN",
    stripePrice: "price_1Q2CvNK2N6ksdlaATqaqBs51"
  },
  {
    id: 2,
    src: basic,
    title: "Estándar",
    description: "Perfecto para corredores y agencias inmobiliarias de tamaño mediano, Publicación máxima 30 propiedades",
    price: "2,800,00 MXN",
    stripePrice: "price_1Q2D6GK2N6ksdlaAkU14xVkD"
  },
  {
    id: 3,
    src: basic,
    title: "Premium",
    description: "Diseñado para grandes agencias inmobiliarias con alta rotación de propiedades, Publicación máxima 50 propiedades",
    price: "3,500,00 MXN",
    stripePrice: "price_1Q2D6xK2N6ksdlaAHFVQf3Qq"
  },
];
