import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function LoginAgent() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  function handleChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        navigate("/");
      }
    } catch (error) {
      toast.error("Email or password is incorrect");
    }
  }
  return (
    <main className="flex-1 min-w-0 max-w-2xl mx-auto h-[88vh] px-6 md:px-0 font-montserrat flex items-center justify-center">
      <div className="w-full">
        <h1 className="font-palatino-lt-serif text-3xl md:text-5xl font-medium uppercase">
          Inicio Sesión Agente
        </h1>

        <form onSubmit={handleSubmit}>
          <div className="mt-8 mb-3 md:mb-8">
            <label className="text-sm mb-1" htmlFor="">
              Correo Electrónico - <span className="text-gray-500">requerido</span>
            </label>
            <input
              onChange={handleChange}
              id="email"
              value={email}
              className="w-full py-1.5 border border-gray-300 focus:outline-none focus:border-cyan-600
                      pl-4 caret-gray-300"
              type="text"
            />
          </div>
          <div className="mt-8">
            <label className="text-sm mb-1" htmlFor="">
              Contraseña - <span className="text-gray-500">requerido</span>
            </label>
            <input
              onChange={handleChange}
              id="password"
              value={password}
              className="w-full py-1.5 border border-gray-300 focus:outline-none focus:border-cyan-600
                      pl-4 caret-gray-300"
              type="password"
            />
          </div>
          <Link
            className="text-gray-400 hover:underline duration-300 text-sm cursor-pointer leading-6"
            to={"/forgot-password"}
          >
            No recuerdas tu contraseña?
          </Link>
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-3 md:space-y-0 mt-6 mb-3 md:mb-8">
            <button
              className="uppercase w-full py-3 text-white text-sm font-medium tracking-wider
                  bg-[#18446b] hover:bg-opacity-70 duration-300"
            >
              Iniciar Sesión
            </button>

            <div className="relative w-full">
              <Link
                to={"/register-agent"}
                className="flex items-center justify-center uppercase w-full py-3 text-black text-sm font-medium tracking-wider
                      border border-transparent hover:border-[#18446b] duration-300"
              >
                Nuevo Agente
                <BsArrowRight className="ml-2" />
              </Link>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}
