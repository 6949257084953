import React from "react";
import Item from "./Item";
import { LISTINGS, CITIES, CONTACTS } from "../shared/constants";
import { useFetchCommunities } from "../hooks/useFetchCommunities";

export default function ItemsContainer() {
  const { data: communities, loading: loadingCommunities } = useFetchCommunities({ filters: { isFeatured: true } });
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 px-5 sm:px-8 py-16">
      <Item 
        loading={loadingCommunities} 
        links={
          communities.map(({ data: { slug, overview }}) => (
            { label: overview?.title, href: `/community/${slug}` }
          ))
        } 
        title="DESARROLLOS" />
      <Item links={LISTINGS} title="LISTINGS" />
      <Item links={CITIES} title="CIUDADES" />
      <Item links={CONTACTS} title="CONTACTO" />
    </div>
  );
}
